import { startOfWeek, addWeeks, isWeekend } from 'date-fns';

export function getNextBusinessWeek(): Date {
  const today = new Date();
  // Set weekStartsOn to 1 (Monday)
  const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });

  // If today is a weekend, return the start of next week
  if (isWeekend(today)) {
    return addWeeks(startOfCurrentWeek, 1);
  }

  return startOfCurrentWeek;
}