import React, { useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { useCalendar } from '../../../contexts/CalendarContext';
import { useSavedUnits } from '../../../contexts/SavedUnitsContext';
import CalendarGrid from '../../../components/Planning/Calendar/CalendarGrid';
import UnitSidebar from '../../../components/Planning/Calendar/UnitSidebar';
import NonSchoolDayModal from '../../../components/Planning/Calendar/NonSchoolDayModal';
import { Plus } from 'lucide-react';
import type { SavedUnit } from '../../../types';

const subjectColors: Record<string, string> = {
  Math: '#e0f2fe',
  Science: '#dcfce7',
  English: '#fef3c7',
  'Social Studies': '#fce7f3',
  Other: '#f3e8ff',
};

export default function Calendar() {
  const [showNonSchoolDayModal, setShowNonSchoolDayModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { state: calendarState, addEvent, calculateEndDate, deleteEvent, hasOverlap } = useCalendar();
  const { state: unitsState } = useSavedUnits();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    const unitId = active.id.toString().replace('unit-', '');
    const unit = unitsState.units.find((u: SavedUnit) => u.id === unitId);
    const dateString = over.id.toString().replace('calendar-', '');
    
    if (!unit || !dateString) return;

    // Check if this unit is already placed
    const existingEvent = calendarState.events.find(event => event.unitId === unitId);
    if (existingEvent) {
      // Always remove the existing event
      deleteEvent(existingEvent.id);
    }

    const startDate = new Date(dateString);
    const duration = parseInt(unit.duration);
    
    if (isNaN(duration)) return;

    const endDate = calculateEndDate(startDate, duration);

    // Only check for overlap with other units (not itself)
    if (hasOverlap(startDate, endDate, unit.subject, existingEvent?.id)) {
      setError(`Cannot place unit here - overlaps with another ${unit.subject} unit`);
      setTimeout(() => setError(null), 3000);
      return;
    }

    // Add the event at the new position
    addEvent({
      unitId,
      title: `Unit ${unit.unitNumber}: ${unit.unit_name}`,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      duration,
      backgroundColor: subjectColors[unit.subject] || subjectColors.Other,
      subject: unit.subject,
    });
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div className="h-[calc(100vh-theme(spacing.32))]">
        <div className="mb-6 flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">School Year Calendar</h1>
            <p className="text-gray-500">Schedule your units and manage non-school days</p>
          </div>
          <button
            onClick={() => setShowNonSchoolDayModal(true)}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4" />
            Add Non-School Day
          </button>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        <div className="flex gap-6 h-full">
          <UnitSidebar units={unitsState.units} />
          <div className="flex-1 bg-white rounded-xl shadow-sm overflow-hidden">
            <CalendarGrid />
          </div>
        </div>

        {showNonSchoolDayModal && (
          <NonSchoolDayModal onClose={() => setShowNonSchoolDayModal(false)} />
        )}
      </div>
    </DndContext>
  );
}