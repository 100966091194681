import React from 'react';
import { Loader2 } from 'lucide-react';

interface PlanFormProps {
  input: string;
  isLoading: boolean;
  onInputChange: (value: string) => void;
  onSubmit: (e: React.FormEvent) => void;
}

export default function PlanForm({ input, isLoading, onInputChange, onSubmit }: PlanFormProps) {
  return (
    <form onSubmit={onSubmit} className="mb-8">
      <div className="space-y-4">
        <div>
          <label htmlFor="planInput" className="block text-sm font-medium text-gray-700">
            Describe your curriculum needs
          </label>
          <textarea
            id="planInput"
            rows={4}
            value={input}
            onChange={(e) => onInputChange(e.target.value)}
            placeholder="Example: 5th grade math curriculum focusing on fractions, decimals, and basic geometry"
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>
        <div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full sm:w-auto px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? (
              <span className="flex items-center gap-2">
                <Loader2 className="h-4 w-4 animate-spin" />
                Generating Plan...
              </span>
            ) : (
              'Generate Plan'
            )}
          </button>
        </div>
      </div>
    </form>
  );
}