import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useLessons } from '../../../contexts/LessonContext';
import { useSavedUnits } from '../../../contexts/SavedUnitsContext';
import { useCalendar } from '../../../contexts/CalendarContext';
import type { SavedUnit, CalendarEvent } from '../../../types';
import LessonList from '../../../components/Planning/Lessons/LessonList';
import LessonForm from '../../../components/Planning/Lessons/LessonForm';
import { eachDayOfInterval, parseISO, isWeekend, addDays, isSameDay } from 'date-fns';

export default function LessonPlanner() {
  const { unitId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const event = location.state?.event as CalendarEvent;
  const { state: unitsState } = useSavedUnits();
  const { state: lessonState } = useLessons();
  const { state: calendarState } = useCalendar();
  const [unit, setUnit] = useState<SavedUnit | null>(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (unitId) {
      const foundUnit = unitsState.units.find(u => u.id === unitId);
      if (foundUnit) {
        setUnit(foundUnit);
      }
    }
  }, [unitId, unitsState.units]);

  if (!unit || !event) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-500">Unit not found</p>
      </div>
    );
  }

  // Get all weekdays between start and end date, excluding weekends and non-school days
  const schoolDays = eachDayOfInterval({
    start: parseISO(event.start),
    end: parseISO(event.end)
  }).filter(date => {
    const isWeekendDay = isWeekend(date);
    const isNonSchoolDay = calendarState.nonSchoolDays.some(nonSchoolDay =>
      isSameDay(parseISO(nonSchoolDay.date), date)
    );
    return !isWeekendDay && !isNonSchoolDay;
  });

  const lessons = lessonState.lessons[unitId] || [];

  return (
    <div className="max-w-4xl mx-auto">
      <button
        onClick={() => navigate('/planning/calendar')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="h-4 w-4" />
        Back to Calendar
      </button>

      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">
          Lesson Planning: {unit.unit_name}
        </h1>
        <p className="text-gray-500">
          Plan daily lessons for Unit {unit.unitNumber}
        </p>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Lessons</h2>
            <p className="text-sm text-gray-500">
              {lessons.length} of {schoolDays.length} lessons planned
            </p>
          </div>
          <button
            onClick={() => setShowForm(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            Add Lesson
          </button>
        </div>

        <LessonList
          lessons={lessons}
          unit={unit}
          schoolDays={schoolDays}
          event={event}
        />

        {showForm && (
          <LessonForm
            unit={unit}
            onClose={() => setShowForm(false)}
            schoolDays={schoolDays}
            existingLessons={lessons}
            event={event}
          />
        )}
      </div>
    </div>
  );
}