import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeft, ChevronDown, ChevronUp } from 'lucide-react';
import { usePlanRequests } from '../../contexts/PlanRequestContext';
import { useAlerts } from '../../contexts/AlertContext';
import PlanForm from '../../components/Planning/PlanForm';
import PlanUnit from '../../components/Planning/PlanUnit';
import UnitSelectionModal from '../../components/Planning/UnitSelectionModal';
import type { Plan } from '../../types';
import { format } from 'date-fns';

const loadingMessages = [
  "Analyzing curriculum standards...",
  "Structuring learning objectives...",
  "Organizing units and lessons...",
  "This takes a bit of time...",
  "This is planning for a full year, OK?",
  "Feel free to leave and receive alert when complete",
  "Finalizing your year-long plan...",
];

export default function FullYearPlan() {
  const [input, setInput] = useState('');
  const [plan, setPlan] = useState<Plan | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [expandedHistory, setExpandedHistory] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addRequest, state: { requests } } = usePlanRequests();
  const { addAlert } = useAlerts();

  useEffect(() => {
    const requestId = searchParams.get('requestId');
    if (requestId) {
      const savedRequest = requests.find(req => req.id === requestId);
      if (savedRequest) {
        try {
          const planData = JSON.parse(savedRequest.output);
          if (planData && Array.isArray(planData.units)) {
            setPlan(planData);
            setInput(savedRequest.input);
          } else {
            throw new Error('Invalid plan data format');
          }
        } catch (err) {
          setError('Failed to load saved plan');
          console.error('Error loading plan:', err);
        }
      } else {
        setError('Plan not found');
      }
    }
  }, [searchParams, requests]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setLoadingMessageIndex((prev) => 
          prev < loadingMessages.length - 1 ? prev + 1 : prev
        );
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    setIsLoading(true);
    setError(null);
    setPlan(null);
    setLoadingMessageIndex(0);

    const currentPath = location.pathname;
    const requestId = Math.random().toString(36).substr(2, 9);

    try {
      const response = await fetch('https://hook.us1.make.com/y2dqkoibcp4pn1if3guu406awg73kh35', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messageRequest: input,
          requestingUser: 'user123',
          requestURL: window.location.origin + currentPath,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate plan');
      }

      const data = await response.json();
      
      if (!data || !Array.isArray(data.units)) {
        throw new Error('Invalid response format');
      }
      
      setPlan(data);

      const request = {
        id: requestId,
        userId: 'user123',
        timestamp: new Date().toISOString(),
        input: input, // Use the original input
        output: JSON.stringify(data),
        url: `${window.location.origin}${currentPath}?requestId=${requestId}`,
        // Store the sonnet fields from the response
        sonnetInput: data.sonnetInput || null,
        sonnetOutput: data.sonnetOutput || null
      };
      
      addRequest(request);

      addAlert({
        type: 'info',
        message: `Your curriculum plan for "${input}" is ready to view!`,
        date: new Date().toISOString(),
        metadata: {
          type: 'plan_ready',
          path: `${currentPath}?requestId=${requestId}`,
          requestId,
        },
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      setError(errorMessage);
      console.error('Error generating plan:', err);
      addAlert({
        type: 'warning',
        message: `Failed to generate curriculum plan for "${input}"`,
        date: new Date().toISOString(),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadPreviousPlan = (requestId: string) => {
    navigate(`?requestId=${requestId}`);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <button
        onClick={() => navigate('/planning')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="h-4 w-4" />
        Back to Planning Tools
      </button>

      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Year-Long Plan Generator</h1>
        <p className="text-gray-500">Create a comprehensive year-long curriculum plan</p>
      </div>

      <PlanForm
        input={input}
        isLoading={isLoading}
        onInputChange={setInput}
        onSubmit={handleSubmit}
      />

      {requests.length > 0 && (
        <div className="mt-8 bg-white rounded-lg shadow-sm overflow-hidden">
          <button
            onClick={() => setExpandedHistory(!expandedHistory)}
            className="w-full px-4 py-3 flex items-center justify-between text-left hover:bg-gray-50"
          >
            <span className="font-medium text-gray-900">Previous Plans</span>
            {expandedHistory ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </button>
          
          {expandedHistory && (
            <div className="divide-y divide-gray-200">
              {requests.map((request) => (
                <button
                  key={request.id}
                  onClick={() => loadPreviousPlan(request.id)}
                  className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 text-left"
                >
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      {request.input}
                    </p>
                    <p className="text-xs text-gray-500">
                      Generated on {format(new Date(request.timestamp), 'MMM d, yyyy h:mm a')}
                    </p>
                  </div>
                  <ArrowLeft className="h-4 w-4 text-gray-400 rotate-180" />
                </button>
              ))}
            </div>
          )}
        </div>
      )}

      {isLoading && (
        <div className="text-center py-12">
          <div className="animate-pulse space-y-4">
            <p className="text-gray-600">{loadingMessages[loadingMessageIndex]}</p>
          </div>
        </div>
      )}

      {error && (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-8">
          <p className="text-red-800">{error}</p>
        </div>
      )}

      {plan && plan.units && (
        <>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-900">Generated Units</h2>
            <button
              onClick={() => setShowSelectionModal(true)}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            >
              Save Units to My Plan
            </button>
          </div>

          <div className="space-y-8">
            {plan.units.map((unit, index) => (
              <PlanUnit key={index} unit={unit} index={index} />
            ))}
          </div>

          {showSelectionModal && (
            <UnitSelectionModal
              units={plan.units}
              onClose={() => setShowSelectionModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
}