import React, { useEffect, useRef } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { 
  format, 
  startOfMonth, 
  addMonths,
  subMonths,
  eachDayOfInterval, 
  endOfMonth, 
  isWeekend,
  startOfWeek,
  endOfWeek,
  isSameMonth
} from 'date-fns';
import { useCalendar } from '../../../contexts/CalendarContext';
import EventPopover from './EventPopover';
import { parseISO, isSameDay } from 'date-fns';

export default function CalendarGrid() {
  const currentMonthRef = useRef<HTMLDivElement>(null);
  const [selectedEvent, setSelectedEvent] = React.useState<string | null>(null);
  const { state, updateEvent, deleteEvent } = useCalendar();
  const currentMonth = new Date().toISOString().slice(0, 7); // YYYY-MM format

  // Generate 18 months (6 months before current month + current month + 11 months after)
  const months = [
    ...Array.from({ length: 6 }, (_, i) => 
      startOfMonth(subMonths(new Date(), 6 - i))
    ),
    ...Array.from({ length: 12 }, (_, i) => 
      startOfMonth(addMonths(new Date(), i))
    ),
  ];

  useEffect(() => {
    // Scroll to current month on initial render
    const monthElement = document.querySelector(`[data-month="${currentMonth}"]`);
    if (monthElement) {
      monthElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [currentMonth]);

  const renderDay = (date: Date, monthStart: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const { setNodeRef } = useDroppable({
      id: `calendar-${dateStr}`,
    });

    const isWeekendDay = isWeekend(date);
    const isNonSchoolDay = state.nonSchoolDays.some(nonSchoolDay =>
      isSameDay(parseISO(nonSchoolDay.date), date)
    );
    const isCurrentMonth = isSameMonth(date, monthStart);

    const events = state.events.filter(event => {
      const eventStart = parseISO(event.start);
      const eventEnd = parseISO(event.end);
      return date >= eventStart && date <= eventEnd;
    });

    const nonSchoolDayInfo = state.nonSchoolDays.find(day => 
      isSameDay(parseISO(day.date), date)
    );

    return (
      <div
        key={dateStr}
        ref={setNodeRef}
        className={`min-h-[100px] p-2 border-r border-b ${
          !isCurrentMonth ? 'bg-gray-50/50 text-gray-400' :
          isWeekendDay || isNonSchoolDay ? 'bg-gray-50' : 'bg-white'
        }`}
      >
        <div className="flex items-start justify-between mb-2">
          <span className={`text-sm ${
            !isCurrentMonth ? 'text-gray-400' :
            isWeekendDay || isNonSchoolDay ? 'text-gray-400' : 'text-gray-600'
          }`}>
            {format(date, 'd')}
          </span>
          {nonSchoolDayInfo && isCurrentMonth && (
            <span className="text-xs px-2 py-1 bg-gray-100 text-gray-600 rounded">
              {nonSchoolDayInfo.type}
            </span>
          )}
        </div>
        {nonSchoolDayInfo && isCurrentMonth && (
          <p className="text-xs text-gray-500">{nonSchoolDayInfo.description}</p>
        )}
        {isCurrentMonth && events.map(event => (
          <div
            key={event.id}
            onClick={() => setSelectedEvent(event.id)}
            className="mt-1 px-2 py-1 text-xs rounded cursor-pointer truncate"
            style={{ backgroundColor: event.backgroundColor }}
          >
            {event.title}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="h-full overflow-y-auto">
      {months.map(month => {
        const monthStart = startOfMonth(month);
        const monthEnd = endOfMonth(month);
        const calendarStart = startOfWeek(monthStart);
        const calendarEnd = endOfWeek(monthEnd);

        return (
          <div
            key={month.toISOString()}
            className="mb-8"
            data-month={month.toISOString().slice(0, 7)}
            ref={month.toISOString().slice(0, 7) === currentMonth ? currentMonthRef : null}
          >
            <h3 className="text-lg font-semibold text-gray-900 mb-4 px-4">
              {format(month, 'MMMM yyyy')}
            </h3>
            <div className="grid grid-cols-7 border-l border-t">
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                <div key={day} className="py-2 text-center text-sm font-medium text-gray-600 border-r border-b bg-gray-50">
                  {day}
                </div>
              ))}
              {eachDayOfInterval({
                start: calendarStart,
                end: calendarEnd,
              }).map(date => renderDay(date, month))}
            </div>
          </div>
        );
      })}

      {selectedEvent && (
        <EventPopover
          event={state.events.find(e => e.id === selectedEvent)!}
          onClose={() => setSelectedEvent(null)}
          onDelete={deleteEvent}
          onUpdate={updateEvent}
        />
      )}
    </div>
  );
}