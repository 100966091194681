import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { Lesson, SavedUnit } from '../../../types';
import { useLessons } from '../../../contexts/LessonContext';
import { format } from 'date-fns';

interface LessonFormProps {
  unit: SavedUnit;
  onClose: () => void;
  schoolDays: Date[];
  existingLessons: Lesson[];
  editingLesson?: Lesson;
}

export default function LessonForm({ unit, onClose, schoolDays, existingLessons, editingLesson }: LessonFormProps) {
  const { addLesson, updateLesson } = useLessons();
  const nextOrder = editingLesson ? editingLesson.order : existingLessons.length;

  const [formData, setFormData] = useState<Partial<Lesson>>({
    title: editingLesson?.title || '',
    description: editingLesson?.description || '',
    standards: editingLesson?.standards || [],
    learningTargets: editingLesson?.learningTargets || [],
    resources: editingLesson?.resources || '',
    order: nextOrder,
    date: editingLesson 
      ? editingLesson.date 
      : format(schoolDays[0], 'yyyy-MM-dd'),
  });

  const [isEnhancing, setIsEnhancing] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.title && formData.standards && formData.learningTargets) {
      const lessonData = {
        ...formData,
        unitId: unit.id,
      } as Lesson;

      if (editingLesson) {
        updateLesson({
          ...editingLesson,
          ...lessonData,
        });
      } else {
        addLesson(unit.id, lessonData);
      }
      onClose();
    }
  };

  const handleEnhanceLesson = async () => {
    setIsEnhancing(true);
    try {
      const response = await fetch('https://hook.us1.make.com/ft7kouh94fyvt4pkaecxsvye7s648ghq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          unitName: unit.unit_name,
          lessonTitle: formData.title,
          standards: formData.standards.map(standardId => {
            const standard = unit.standards_covered.find(s => s.standard === standardId);
            return standard ? `${standard.standard}: ${standard.description}` : standardId;
          }),
          learningTargets: formData.learningTargets.map(targetId => 
            unit.learning_targets[parseInt(targetId)]
          ),
          currentDescription: formData.description,
          resources: formData.resources,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to enhance lesson');
      }

      const data = await response.json();
      setFormData(prev => ({
        ...prev,
        description: data.updatedDescription || prev.description,
      }));
    } catch (error) {
      console.error('Error enhancing lesson:', error);
    } finally {
      setIsEnhancing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
          Date
        </label>
        <select
          id="date"
          value={formData.date}
          onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          required
        >
          {schoolDays.map((day) => (
            <option key={format(day, 'yyyy-MM-dd')} value={format(day, 'yyyy-MM-dd')}>
              {format(day, 'EEEE, MMMM d')}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
          Lesson Title
        </label>
        <input
          type="text"
          id="title"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Standards Covered
        </label>
        <div className="space-y-2">
          {unit.standards_covered.map((standard) => (
            <label key={standard.standard} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.standards?.includes(standard.standard)}
                onChange={(e) => {
                  const standards = formData.standards || [];
                  if (e.target.checked) {
                    setFormData({
                      ...formData,
                      standards: [...standards, standard.standard],
                    });
                  } else {
                    setFormData({
                      ...formData,
                      standards: standards.filter((s) => s !== standard.standard),
                    });
                  }
                }}
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <span className="text-sm text-gray-900">
                {standard.standard}: {standard.description}
              </span>
            </label>
          ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Learning Targets
        </label>
        <div className="space-y-2">
          {unit.learning_targets.map((target, index) => (
            <label key={index} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.learningTargets?.includes(index.toString())}
                onChange={(e) => {
                  const targets = formData.learningTargets || [];
                  if (e.target.checked) {
                    setFormData({
                      ...formData,
                      learningTargets: [...targets, index.toString()],
                    });
                  } else {
                    setFormData({
                      ...formData,
                      learningTargets: targets.filter((t) => t !== index.toString()),
                    });
                  }
                }}
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <span className="text-sm text-gray-900">{target}</span>
            </label>
          ))}
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between mb-1">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Lesson Description
          </label>
          <button
            type="button"
            onClick={handleEnhanceLesson}
            disabled={isEnhancing}
            className="text-sm text-indigo-600 hover:text-indigo-800"
          >
            {isEnhancing ? 'Enhancing...' : 'Ninja It!'}
          </button>
        </div>
        <textarea
          id="description"
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          rows={4}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      <div>
        <label htmlFor="resources" className="block text-sm font-medium text-gray-700 mb-1">
          Resources
        </label>
        <textarea
          id="resources"
          value={formData.resources}
          onChange={(e) => setFormData({ ...formData, resources: e.target.value })}
          rows={2}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="List any resources needed for this lesson"
        />
      </div>

      <div className="flex justify-end gap-3">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
        >
          {editingLesson ? 'Save Changes' : 'Add Lesson'}
        </button>
      </div>
    </form>
  );
}