import React, { createContext, useContext, useReducer } from 'react';
import type { PlanRequest } from '../types';

interface PlanRequestState {
  requests: PlanRequest[];
}

type PlanRequestAction = 
  | { type: 'ADD_REQUEST'; payload: PlanRequest }
  | { type: 'CLEAR_REQUESTS' };

interface PlanRequestContextType {
  state: PlanRequestState;
  addRequest: (request: PlanRequest) => void;
  clearRequests: () => void;
}

const PlanRequestContext = createContext<PlanRequestContextType | undefined>(undefined);

const initialState: PlanRequestState = {
  requests: [],
};

function planRequestReducer(state: PlanRequestState, action: PlanRequestAction): PlanRequestState {
  switch (action.type) {
    case 'ADD_REQUEST':
      return {
        ...state,
        requests: [action.payload, ...state.requests],
      };
    case 'CLEAR_REQUESTS':
      return {
        ...state,
        requests: [],
      };
    default:
      return state;
  }
}

export function PlanRequestProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(planRequestReducer, initialState);

  const addRequest = (request: PlanRequest) => {
    dispatch({ type: 'ADD_REQUEST', payload: request });
  };

  const clearRequests = () => {
    dispatch({ type: 'CLEAR_REQUESTS' });
  };

  return (
    <PlanRequestContext.Provider value={{ state, addRequest, clearRequests }}>
      {children}
    </PlanRequestContext.Provider>
  );
}

export function usePlanRequests() {
  const context = useContext(PlanRequestContext);
  if (context === undefined) {
    throw new Error('usePlanRequests must be used within a PlanRequestProvider');
  }
  return context;
}