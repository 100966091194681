import { createContext, useContext, useEffect, useState } from 'react';
import { signIn, signUp, signOut, getCurrentUser, confirmSignUp, AuthUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

interface AuthState {
  user: AuthUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  unverifiedEmail: string | null;
}

interface AuthContextType {
  state: AuthState;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, name: string, schoolName: string, zipCode: string) => Promise<void>;
  verifyEmail: (email: string, code: string) => Promise<void>;
  logout: () => Promise<void>;
  clearError: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<AuthState>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    error: null,
    unverifiedEmail: null
  });

  useEffect(() => {
    checkUser();
    setupAuthListener();
  }, []);

  async function checkUser() {
    try {
      const user = await getCurrentUser();
      setState(prev => ({
        ...prev,
        user,
        isAuthenticated: true,
        isLoading: false,
      }));
    } catch (error) {
      setState(prev => ({
        ...prev,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      }));
    }
  }

  function setupAuthListener() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signedIn':
          setState(prev => ({
            ...prev,
            user: data,
            isAuthenticated: true,
            error: null,
          }));
          break;
        case 'signedOut':
          setState(prev => ({
            ...prev,
            user: null,
            isAuthenticated: false,
            error: null,
          }));
          break;
      }
    });
  }

  async function login(email: string, password: string) {
    try {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      const { isSignedIn, nextStep } = await signIn({ username: email, password });
      if (isSignedIn) {
        const user = await getCurrentUser();
        setState(prev => ({
          ...prev,
          user,
          isAuthenticated: true,
          isLoading: false,
        }));
      }
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Login failed',
        isLoading: false,
      }));
      throw error;
    }
  }

  async function register(email: string, password: string, name: string, schoolName: string, zipCode: string) {
    try {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            name,
            // Store school name and zip code in a profile field until custom attributes are configured
            profile: JSON.stringify({ schoolName, zipCode }),
          },
          autoSignIn: true
        },
      });
      setState(prev => ({
        ...prev,
        isLoading: false,
        unverifiedEmail: email,
      }));
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Registration failed',
        isLoading: false,
      }));
      throw error;
    }
  }

  async function verifyEmail(email: string, code: string) {
    try {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      await confirmSignUp({ username: email, confirmationCode: code });
      setState(prev => ({
        ...prev,
        isLoading: false,
        unverifiedEmail: null,
      }));
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Verification failed',
        isLoading: false,
      }));
      throw error;
    }
  }

  async function logout() {
    try {
      await signOut();
      setState(prev => ({
        ...prev,
        user: null,
        isAuthenticated: false,
        error: null,
      }));
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Logout failed',
      }));
    }
  }

  function clearError() {
    setState(prev => ({ ...prev, error: null }));
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        register,
        verifyEmail,
        logout,
        clearError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}