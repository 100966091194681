import React from 'react';
import AlertsWidget from '../components/Dashboard/AlertsWidget';
import TasksWidget from '../components/Dashboard/TasksWidget';
import { Users, BookOpen, MessageSquare, TrendingUp } from 'lucide-react';

const stats = [
  {
    label: 'Total Students',
    value: '28',
    icon: Users,
    change: '+2',
    changeType: 'increase',
  },
  {
    label: 'Avg. Reading Level',
    value: '3.8',
    icon: BookOpen,
    change: '+0.3',
    changeType: 'increase',
  },
  {
    label: 'Parent Messages',
    value: '12',
    icon: MessageSquare,
    change: '-3',
    changeType: 'decrease',
  },
  {
    label: 'Class Progress',
    value: '87%',
    icon: TrendingUp,
    change: '+5%',
    changeType: 'increase',
  },
];

export default function Dashboard() {
  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
        <p className="text-gray-500">Welcome back, Sarah! Here's your overview.</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map(({ label, value, icon: Icon, change, changeType }) => (
          <div
            key={label}
            className="bg-white rounded-xl shadow-sm p-6 flex items-start justify-between"
          >
            <div>
              <p className="text-sm font-medium text-gray-600">{label}</p>
              <p className="text-2xl font-semibold text-gray-900 mt-1">
                {value}
              </p>
              <p
                className={`text-sm mt-1 ${
                  changeType === 'increase'
                    ? 'text-green-600'
                    : 'text-red-600'
                }`}
              >
                {change} from last week
              </p>
            </div>
            <div className="p-3 bg-indigo-50 rounded-lg">
              <Icon className="h-6 w-6 text-indigo-600" />
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <AlertsWidget />
        <TasksWidget />
      </div>
    </div>
  );
}