import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { SavedUnit } from '../../types';
import SubjectSelect from './SubjectSelect';

interface EditUnitModalProps {
  unit: SavedUnit;
  onClose: () => void;
  onSave: (unit: SavedUnit) => void;
}

export default function EditUnitModal({ unit, onClose, onSave }: EditUnitModalProps) {
  const [editedUnit, setEditedUnit] = useState<SavedUnit>(unit);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (editedUnit.unit_name && editedUnit.standards_covered && editedUnit.learning_targets) {
      // Calculate total duration from standards
      const totalDays = editedUnit.standards_covered.reduce(
        (sum, standard) => sum + parseInt(standard.duration) || 0,
        0
      );

      onSave({
        ...editedUnit,
        duration: `${totalDays} days`,
      });
    }
  };

  const addStandard = () => {
    setEditedUnit({
      ...editedUnit,
      standards_covered: [
        ...editedUnit.standards_covered,
        { standard: '', description: '', duration: '0 days' },
      ],
    });
  };

  const updateStandard = (index: number, field: string, value: string) => {
    const newStandards = [...editedUnit.standards_covered];
    newStandards[index] = { ...newStandards[index], [field]: value };
    
    // Update total duration
    const totalDays = newStandards.reduce(
      (sum, standard) => sum + (parseInt(standard.duration) || 0),
      0
    );

    setEditedUnit({
      ...editedUnit,
      standards_covered: newStandards,
      duration: `${totalDays} days`,
    });
  };

  const removeStandard = (index: number) => {
    const newStandards = [...editedUnit.standards_covered];
    newStandards.splice(index, 1);
    
    // Update total duration
    const totalDays = newStandards.reduce(
      (sum, standard) => sum + (parseInt(standard.duration) || 0),
      0
    );

    setEditedUnit({
      ...editedUnit,
      standards_covered: newStandards,
      duration: `${totalDays} days`,
    });
  };

  const addLearningTarget = () => {
    setEditedUnit({
      ...editedUnit,
      learning_targets: [...editedUnit.learning_targets, ''],
    });
  };

  const updateLearningTarget = (index: number, value: string) => {
    const newTargets = [...editedUnit.learning_targets];
    newTargets[index] = value;
    setEditedUnit({
      ...editedUnit,
      learning_targets: newTargets,
    });
  };

  const removeLearningTarget = (index: number) => {
    const newTargets = [...editedUnit.learning_targets];
    newTargets.splice(index, 1);
    setEditedUnit({
      ...editedUnit,
      learning_targets: newTargets,
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">Edit Unit</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 overflow-y-auto max-h-[calc(90vh-129px)]">
          <div className="space-y-6">
            <div>
              <label htmlFor="unit_name" className="block text-sm font-medium text-gray-700 mb-1">
                Unit Name
              </label>
              <input
                type="text"
                id="unit_name"
                value={editedUnit.unit_name}
                onChange={(e) =>
                  setEditedUnit({ ...editedUnit, unit_name: e.target.value })
                }
                className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                Subject
              </label>
              <SubjectSelect
                value={editedUnit.subject}
                onChange={(value) => setEditedUnit({ ...editedUnit, subject: value })}
              />
            </div>

            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Standards Covered
                </label>
                <span className="text-sm text-gray-500">
                  Total Duration: {editedUnit.duration}
                </span>
              </div>
              <div className="space-y-4">
                {editedUnit.standards_covered.map((standard, index) => (
                  <div key={index} className="flex gap-4">
                    <div className="flex-1">
                      <input
                        type="text"
                        value={standard.standard}
                        onChange={(e) =>
                          updateStandard(index, 'standard', e.target.value)
                        }
                        placeholder="Standard (e.g., MATH.5.NBT.1)"
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                      />
                    </div>
                    <div className="flex-[2]">
                      <input
                        type="text"
                        value={standard.description}
                        onChange={(e) =>
                          updateStandard(index, 'description', e.target.value)
                        }
                        placeholder="Description"
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                      />
                    </div>
                    <div className="w-24">
                      <input
                        type="number"
                        value={parseInt(standard.duration) || 0}
                        onChange={(e) =>
                          updateStandard(index, 'duration', `${e.target.value} days`)
                        }
                        placeholder="Days"
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                        min="0"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => removeStandard(index)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addStandard}
                  className="text-sm text-indigo-600 hover:text-indigo-800"
                >
                  + Add Standard
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Learning Targets
              </label>
              <div className="space-y-4">
                {editedUnit.learning_targets.map((target, index) => (
                  <div key={index} className="flex gap-4">
                    <div className="flex-1">
                      <input
                        type="text"
                        value={target}
                        onChange={(e) =>
                          updateLearningTarget(index, e.target.value)
                        }
                        placeholder="I can..."
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => removeLearningTarget(index)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addLearningTarget}
                  className="text-sm text-indigo-600 hover:text-indigo-800"
                >
                  + Add Learning Target
                </button>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}