import React from 'react';
import type { Unit } from '../../types';

interface PlanUnitProps {
  unit: Unit;
  index: number;
}

export default function PlanUnit({ unit, index }: PlanUnitProps) {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="space-y-4">
        <h3 className="text-xl font-bold text-gray-900">
          Unit {index + 1}
        </h3>

        <div className="flex items-center justify-between">
          <span className="text-lg text-gray-800">{unit.unit_name}</span>
          <span className="px-4 py-1 bg-indigo-100 text-indigo-800 rounded-full text-sm">
            {unit.duration}
          </span>
        </div>

        <div className="space-y-2">
          <h4 className="font-semibold text-gray-900">Standards Covered</h4>
          <div className="space-y-2 border-l-4 border-indigo-100 pl-4">
            {unit.standards_covered.map((standard, i) => (
              <div key={i} className="flex items-baseline justify-between gap-4">
                <div className="flex-1">
                  <span className="font-medium text-gray-900">{standard.standard}:</span>
                  <span className="text-gray-600 ml-2">{standard.description}</span>
                </div>
                <span className="text-gray-600 whitespace-nowrap">{standard.duration}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-2">
          <h4 className="font-semibold text-gray-900">Learning Targets</h4>
          <ul className="space-y-2">
            {unit.learning_targets.map((target, i) => (
              <li key={i} className="text-gray-600">
                {target}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}