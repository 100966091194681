import React from 'react';
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import type { Lesson, SavedUnit, CalendarEvent } from '../../../types';
import LessonItem from './LessonItem';
import { useLessons } from '../../../contexts/LessonContext';
import { format } from 'date-fns';

interface LessonListProps {
  lessons: Lesson[];
  unit: SavedUnit;
  schoolDays: Date[];
  event: CalendarEvent;
}

export default function LessonList({ lessons, unit, schoolDays, event }: LessonListProps) {
  const { reorderLessons } = useLessons();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = lessons.findIndex((lesson) => lesson.id === active.id);
      const newIndex = lessons.findIndex((lesson) => lesson.id === over.id);

      const newLessons = [...lessons];
      const [movedLesson] = newLessons.splice(oldIndex, 1);
      newLessons.splice(newIndex, 0, movedLesson);

      // Update order numbers and dates based on new order
      const reorderedLessons = newLessons.map((lesson, index) => ({
        ...lesson,
        order: index,
        date: format(schoolDays[index], 'yyyy-MM-dd')
      }));

      reorderLessons(unit.id, reorderedLessons);
    }
  };

  return (
    <div className="space-y-4">
      {lessons.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-500">No lessons planned yet</p>
          <p className="text-sm text-gray-400 mt-1">
            Click "Add Lesson" to start planning
          </p>
        </div>
      ) : (
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={lessons} strategy={verticalListSortingStrategy}>
            <div className="space-y-4">
              {lessons
                .sort((a, b) => a.order - b.order)
                .map((lesson) => (
                  <LessonItem
                    key={lesson.id}
                    lesson={lesson}
                    unit={unit}
                    schoolDays={schoolDays}
                    event={event}
                  />
                ))}
            </div>
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
}