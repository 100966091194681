import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, CalendarDays, ChevronDown, ChevronUp, Pencil, Trash2 } from 'lucide-react';
import { useSavedUnits } from '../../contexts/SavedUnitsContext';
import type { SubjectType, SavedUnit } from '../../types';
import CreateUnitModal from '../../components/Planning/CreateUnitModal';
import EditUnitModal from '../../components/Planning/EditUnitModal';

export default function SavedUnits() {
  const navigate = useNavigate();
  const { state, addUnits, updateUnit, deleteUnit } = useSavedUnits();
  const [selectedSubject, setSelectedSubject] = useState<SubjectType | 'All'>('All');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editingUnit, setEditingUnit] = useState<SavedUnit | null>(null);
  const [expandedUnits, setExpandedUnits] = useState<Set<string>>(new Set());

  const filteredUnits = selectedSubject === 'All' 
    ? state.units 
    : state.units.filter(unit => unit.subject === selectedSubject);

  const toggleExpand = (unitId: string) => {
    const newExpanded = new Set(expandedUnits);
    if (expandedUnits.has(unitId)) {
      newExpanded.delete(unitId);
    } else {
      newExpanded.add(unitId);
    }
    setExpandedUnits(newExpanded);
  };

  const handleDelete = (unitId: string) => {
    if (confirm('Are you sure you want to delete this unit?')) {
      deleteUnit(unitId);
    }
  };

  const handleCreateUnit = (unit: Unit) => {
    addUnits([{ units: [unit], subject: unit.subject }]);
    setShowCreateModal(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-8 flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Unit Plans</h1>
          <p className="text-gray-500">Create and manage your curriculum units</p>
        </div>
        <div className="flex gap-3">
          <button
            onClick={() => setShowCreateModal(true)}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4" />
            Create New Unit
          </button>
          <button
            onClick={() => navigate('/planning/calendar')}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <CalendarDays className="h-4 w-4" />
            Schedule Units
          </button>
        </div>
      </div>

      <div className="mb-6">
        <label htmlFor="subjectFilter" className="block text-sm font-medium text-gray-700 mb-1">
          Filter by Subject
        </label>
        <select
          id="subjectFilter"
          value={selectedSubject}
          onChange={(e) => setSelectedSubject(e.target.value as SubjectType | 'All')}
          className="w-48 rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option value="All">All Subjects</option>
          <option value="Math">Math</option>
          <option value="Science">Science</option>
          <option value="English">English</option>
          <option value="Social Studies">Social Studies</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div className="space-y-6">
        {filteredUnits.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <p className="text-gray-500">No units found</p>
            <button
              onClick={() => setShowCreateModal(true)}
              className="mt-4 text-sm text-indigo-600 hover:text-indigo-800"
            >
              Create your first unit
            </button>
          </div>
        ) : (
          filteredUnits.map((unit) => (
            <div
              key={unit.id}
              className="bg-white rounded-xl shadow-sm p-6"
            >
              <div className="flex items-start justify-between">
                <div>
                  <div className="flex items-center gap-3">
                    <h3 className="text-lg font-semibold text-gray-900">
                      Unit {unit.unitNumber}: {unit.unit_name}
                    </h3>
                    <span className="px-2 py-1 text-sm bg-indigo-100 text-indigo-800 rounded-full">
                      {unit.subject}
                    </span>
                  </div>
                  <p className="text-sm text-gray-500 mt-1">{unit.duration}</p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => setEditingUnit(unit)}
                    className="p-2 text-gray-400 hover:text-gray-600"
                    title="Edit unit"
                  >
                    <Pencil className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(unit.id)}
                    className="p-2 text-red-400 hover:text-red-600"
                    title="Delete unit"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => toggleExpand(unit.id)}
                    className="p-2 text-gray-400 hover:text-gray-600"
                  >
                    {expandedUnits.has(unit.id) ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </button>
                </div>
              </div>

              {expandedUnits.has(unit.id) && (
                <div className="mt-4 space-y-4">
                  <div>
                    <h4 className="font-medium text-gray-900">Standards Covered</h4>
                    <div className="mt-2 space-y-2">
                      {unit.standards_covered.map((standard, index) => (
                        <div key={index} className="flex items-baseline justify-between">
                          <div className="flex-1">
                            <span className="font-medium">{standard.standard}:</span>
                            <span className="ml-2 text-gray-600">{standard.description}</span>
                          </div>
                          <span className="ml-4 text-sm text-gray-500">{standard.duration}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900">Learning Targets</h4>
                    <ul className="mt-2 space-y-1 list-disc list-inside">
                      {unit.learning_targets.map((target, index) => (
                        <li key={index} className="text-gray-600">{target}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {showCreateModal && (
        <CreateUnitModal
          onClose={() => setShowCreateModal(false)}
          onSave={handleCreateUnit}
        />
      )}

      {editingUnit && (
        <EditUnitModal
          unit={editingUnit}
          onClose={() => setEditingUnit(null)}
          onSave={(updatedUnit) => {
            updateUnit({
              ...updatedUnit,
              modified: true,
              lastModified: new Date().toISOString(),
            });
            setEditingUnit(null);
          }}
        />
      )}
    </div>
  );
}