import React, { createContext, useContext, useReducer, useEffect } from 'react';
import type { CalendarEvent, NonSchoolDay, CalendarState } from '../types';
import { addDays, isWeekend, isSameDay, parseISO, startOfDay, endOfDay, isWithinInterval } from 'date-fns';

type CalendarAction =
  | { type: 'ADD_EVENT'; payload: Omit<CalendarEvent, 'id'> }
  | { type: 'UPDATE_EVENT'; payload: CalendarEvent }
  | { type: 'DELETE_EVENT'; payload: string }
  | { type: 'ADD_NON_SCHOOL_DAY'; payload: Omit<NonSchoolDay, 'id'> }
  | { type: 'DELETE_NON_SCHOOL_DAY'; payload: string }
  | { type: 'SET_SCHOOL_YEAR'; payload: { start: string; end: string } };

interface CalendarContextType {
  state: CalendarState;
  addEvent: (event: Omit<CalendarEvent, 'id'>) => void;
  updateEvent: (event: CalendarEvent) => void;
  deleteEvent: (id: string) => void;
  addNonSchoolDay: (day: Omit<NonSchoolDay, 'id'>) => void;
  deleteNonSchoolDay: (id: string) => void;
  setSchoolYear: (start: string, end: string) => void;
  calculateEndDate: (startDate: Date, duration: number) => Date;
  hasOverlap: (start: Date, end: Date, subject: string, excludeEventId?: string) => boolean;
}

const CalendarContext = createContext<CalendarContextType | undefined>(undefined);

const initialState: CalendarState = {
  events: [],
  nonSchoolDays: [],
  schoolYear: {
    start: new Date(new Date().getFullYear(), 7, 1).toISOString(), // August 1st
    end: new Date(new Date().getFullYear() + 1, 5, 30).toISOString(), // June 30th
  },
};

function calendarReducer(state: CalendarState, action: CalendarAction): CalendarState {
  switch (action.type) {
    case 'ADD_EVENT':
      return {
        ...state,
        events: [
          ...state.events,
          {
            ...action.payload,
            id: Math.random().toString(36).substr(2, 9),
          },
        ],
      };
    case 'UPDATE_EVENT':
      return {
        ...state,
        events: state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        ),
      };
    case 'DELETE_EVENT':
      return {
        ...state,
        events: state.events.filter((event) => event.id !== action.payload),
      };
    case 'ADD_NON_SCHOOL_DAY': {
      const newState = {
        ...state,
        nonSchoolDays: [
          ...state.nonSchoolDays,
          {
            ...action.payload,
            id: Math.random().toString(36).substr(2, 9),
          },
        ],
      };
      return newState;
    }
    case 'DELETE_NON_SCHOOL_DAY': {
      const newState = {
        ...state,
        nonSchoolDays: state.nonSchoolDays.filter((day) => day.id !== action.payload),
      };
      return newState;
    }
    case 'SET_SCHOOL_YEAR':
      return {
        ...state,
        schoolYear: action.payload,
      };
    default:
      return state;
  }
}

export function CalendarProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(calendarReducer, initialState);

  const isNonSchoolDay = (date: Date) => {
    return state.nonSchoolDays.some(nonSchoolDay =>
      isSameDay(parseISO(nonSchoolDay.date), date)
    );
  };

  const calculateEndDate = (startDate: Date, duration: number) => {
    let currentDate = startOfDay(startDate);
    let remainingDays = duration;
    let endDate = currentDate;

    while (remainingDays > 0) {
      if (!isWeekend(currentDate) && !isNonSchoolDay(currentDate)) {
        remainingDays--;
        endDate = currentDate;
      }
      if (remainingDays > 0) {
        currentDate = addDays(currentDate, 1);
      }
    }

    return endOfDay(endDate);
  };

  // Update existing events when non-school days change
  useEffect(() => {
    const updatedEvents = state.events.map(event => {
      const startDate = parseISO(event.start);
      const newEndDate = calculateEndDate(startDate, event.duration);
      
      if (!isSameDay(parseISO(event.end), newEndDate)) {
        return {
          ...event,
          end: newEndDate.toISOString()
        };
      }
      return event;
    });

    if (JSON.stringify(updatedEvents) !== JSON.stringify(state.events)) {
      updatedEvents.forEach(event => {
        dispatch({ type: 'UPDATE_EVENT', payload: event });
      });
    }
  }, [state.nonSchoolDays]);

  const hasOverlap = (start: Date, end: Date, subject: string, excludeEventId?: string) => {
    return state.events.some(event => {
      if (excludeEventId && event.id === excludeEventId) return false;
      if (event.subject !== subject) return false;

      const eventStart = parseISO(event.start);
      const eventEnd = parseISO(event.end);

      return (
        isWithinInterval(start, { start: eventStart, end: eventEnd }) ||
        isWithinInterval(end, { start: eventStart, end: eventEnd }) ||
        isWithinInterval(eventStart, { start, end }) ||
        isWithinInterval(eventEnd, { start, end })
      );
    });
  };

  const addEvent = (event: Omit<CalendarEvent, 'id'>) => {
    dispatch({ type: 'ADD_EVENT', payload: event });
  };

  const updateEvent = (event: CalendarEvent) => {
    dispatch({ type: 'UPDATE_EVENT', payload: event });
  };

  const deleteEvent = (id: string) => {
    dispatch({ type: 'DELETE_EVENT', payload: id });
  };

  const addNonSchoolDay = (day: Omit<NonSchoolDay, 'id'>) => {
    dispatch({ type: 'ADD_NON_SCHOOL_DAY', payload: day });
  };

  const deleteNonSchoolDay = (id: string) => {
    dispatch({ type: 'DELETE_NON_SCHOOL_DAY', payload: id });
  };

  const setSchoolYear = (start: string, end: string) => {
    dispatch({ type: 'SET_SCHOOL_YEAR', payload: { start, end } });
  };

  return (
    <CalendarContext.Provider
      value={{
        state,
        addEvent,
        updateEvent,
        deleteEvent,
        addNonSchoolDay,
        deleteNonSchoolDay,
        setSchoolYear,
        calculateEndDate,
        hasOverlap,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}

export function useCalendar() {
  const context = useContext(CalendarContext);
  if (context === undefined) {
    throw new Error('useCalendar must be used within a CalendarProvider');
  }
  return context;
}