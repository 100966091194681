import React, { createContext, useContext, useReducer } from 'react';
import type { Alert } from '../types';

interface AlertState {
  alerts: Alert[];
}

type AlertAction =
  | { type: 'ADD_ALERT'; payload: Alert }
  | { type: 'REMOVE_ALERT'; payload: string }
  | { type: 'MARK_AS_READ'; payload: string };

interface AlertContextType {
  state: AlertState;
  addAlert: (alert: Omit<Alert, 'id'>) => void;
  removeAlert: (id: string) => void;
  markAsRead: (id: string) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

const initialState: AlertState = {
  alerts: [
    {
      id: '1',
      type: 'urgent',
      message: 'Parent-teacher conference scheduled for Tommy B. at 3 PM today',
      date: '2024-03-15T15:00:00',
    },
    {
      id: '2',
      type: 'warning',
      message: '5 students below reading level benchmark',
      date: '2024-03-15',
    },
    {
      id: '3',
      type: 'info',
      message: 'Weekly team meeting tomorrow at 8 AM',
      date: '2024-03-16T08:00:00',
    },
  ],
};

function alertReducer(state: AlertState, action: AlertAction): AlertState {
  switch (action.type) {
    case 'ADD_ALERT':
      return {
        ...state,
        alerts: [action.payload, ...state.alerts],
      };
    case 'REMOVE_ALERT':
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.payload),
      };
    case 'MARK_AS_READ':
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.payload),
      };
    default:
      return state;
  }
}

export function AlertProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(alertReducer, initialState);

  const addAlert = (alert: Omit<Alert, 'id'>) => {
    const newAlert = {
      ...alert,
      id: Math.random().toString(36).substr(2, 9),
    };
    dispatch({ type: 'ADD_ALERT', payload: newAlert });
  };

  const removeAlert = (id: string) => {
    dispatch({ type: 'REMOVE_ALERT', payload: id });
  };

  const markAsRead = (id: string) => {
    dispatch({ type: 'MARK_AS_READ', payload: id });
  };

  return (
    <AlertContext.Provider value={{ state, addAlert, removeAlert, markAsRead }}>
      {children}
    </AlertContext.Provider>
  );
}

export function useAlerts() {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlerts must be used within an AlertProvider');
  }
  return context;
}