import React from 'react';
import { AlertTriangle, Info, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import type { Alert } from '../../types';
import { useAlerts } from '../../contexts/AlertContext';
import { formatDate } from '../../utils/dateFormatter';

const getAlertIcon = (type: Alert['type']) => {
  switch (type) {
    case 'urgent':
      return <AlertTriangle className="h-5 w-5 text-red-500" />;
    case 'warning':
      return <AlertTriangle className="h-5 w-5 text-yellow-500" />;
    case 'info':
      return <Info className="h-5 w-5 text-blue-500" />;
  }
};

const getAlertStyles = (type: Alert['type']) => {
  switch (type) {
    case 'urgent':
      return 'bg-red-50 border-red-100';
    case 'warning':
      return 'bg-yellow-50 border-yellow-100';
    case 'info':
      return 'bg-blue-50 border-blue-100';
  }
};

export default function AlertsWidget() {
  const { state, markAsRead } = useAlerts();
  const navigate = useNavigate();
  const { alerts } = state;

  const handleAlertClick = (alert: Alert) => {
    if (alert.metadata?.type === 'plan_ready' && alert.metadata.path) {
      navigate(alert.metadata.path);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Recent Alerts</h2>
        <div className="flex items-center gap-2">
          {alerts.length > 0 && (
            <span className="text-sm text-gray-500">
              {alerts.length} unread alert{alerts.length !== 1 && 's'}
            </span>
          )}
          <button 
            onClick={() => navigate('/notifications')}
            className="text-sm text-indigo-600 hover:text-indigo-800"
          >
            View All
          </button>
        </div>
      </div>

      <div className="space-y-4">
        {alerts.length === 0 ? (
          <p className="text-center text-gray-500 py-4">No new alerts</p>
        ) : (
          alerts.map((alert) => (
            <div
              key={alert.id}
              onClick={() => handleAlertClick(alert)}
              className={`flex items-start gap-4 p-4 rounded-lg border ${getAlertStyles(
                alert.type
              )} transition-all hover:shadow-sm ${
                alert.metadata?.type === 'plan_ready' ? 'cursor-pointer' : ''
              }`}
            >
              {getAlertIcon(alert.type)}
              <div className="flex-1">
                <p className="text-gray-900">{alert.message}</p>
                <p className="text-sm text-gray-500 mt-1">
                  {formatDate(alert.date)}
                </p>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  markAsRead(alert.id);
                }}
                className="p-1 hover:bg-gray-200 rounded-full transition-colors"
                title="Mark as read"
              >
                <X className="h-4 w-4 text-gray-400" />
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}