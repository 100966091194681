import React, { useState } from 'react';
import { CheckCircle, Circle, Plus, X, AlertCircle } from 'lucide-react';
import { useTasks } from '../../contexts/TaskContext';
import { formatDate } from '../../utils/dateFormatter';
import type { Task } from '../../types';

export default function TasksWidget() {
  const { state, addTask, updateTask, deleteTask, clearError } = useTasks();
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [newTask, setNewTask] = useState({
    title: '',
    dueDate: '',
    priority: 'medium' as const,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newTask.title && newTask.dueDate) {
      try {
        await addTask({
          ...newTask,
          completed: false,
        });
        setNewTask({ title: '', dueDate: '', priority: 'medium' });
        setIsAddingTask(false);
      } catch (error) {
        // Error is handled by the context
      }
    }
  };

  const handleToggleTask = async (task: Task) => {
    try {
      await updateTask({
        ...task,
        completed: !task.completed,
      });
    } catch (error) {
      // Error is handled by the context
    }
  };

  const handleDeleteTask = async (id: string) => {
    try {
      await deleteTask(id);
    } catch (error) {
      // Error is handled by the context
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Tasks</h2>
        <button
          onClick={() => setIsAddingTask(true)}
          className="text-sm text-indigo-600 hover:text-indigo-800 flex items-center gap-1"
        >
          <Plus className="h-4 w-4" />
          Add Task
        </button>
      </div>

      {state.error && (
        <div className="mb-4 p-4 bg-red-50 rounded-lg flex items-center justify-between">
          <div className="flex items-center gap-2 text-red-800">
            <AlertCircle className="h-5 w-5" />
            <p className="text-sm">{state.error}</p>
          </div>
          <button
            onClick={clearError}
            className="text-red-600 hover:text-red-800"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      )}

      {isAddingTask && (
        <form onSubmit={handleSubmit} className="mb-6 p-4 bg-gray-50 rounded-lg">
          <div className="space-y-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Task Title
              </label>
              <input
                type="text"
                id="title"
                value={newTask.title}
                onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">
                Due Date
              </label>
              <input
                type="date"
                id="dueDate"
                value={newTask.dueDate}
                onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                Priority
              </label>
              <select
                id="priority"
                value={newTask.priority}
                onChange={(e) => setNewTask({ ...newTask, priority: e.target.value as 'low' | 'medium' | 'high' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>
            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={() => setIsAddingTask(false)}
                className="px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={state.isLoading}
                className="px-3 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 disabled:opacity-50"
              >
                {state.isLoading ? 'Adding...' : 'Add Task'}
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {state.isLoading && !isAddingTask ? (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600 mx-auto"></div>
          </div>
        ) : state.tasks.length === 0 ? (
          <p className="text-center text-gray-500 py-4">No tasks yet</p>
        ) : (
          state.tasks.map((task) => (
            <div
              key={task.id}
              className={`flex items-center gap-4 p-4 rounded-lg ${
                task.completed ? 'bg-gray-50' : 'bg-white'
              }`}
            >
              <button 
                className="flex-shrink-0"
                onClick={() => handleToggleTask(task)}
                disabled={state.isLoading}
              >
                {task.completed ? (
                  <CheckCircle className="h-5 w-5 text-green-500" />
                ) : (
                  <Circle className="h-5 w-5 text-gray-300" />
                )}
              </button>
              <div className="flex-1 min-w-0">
                <p
                  className={`text-sm ${
                    task.completed ? 'text-gray-500 line-through' : 'text-gray-900'
                  }`}
                >
                  {task.title}
                </p>
                <p className="text-xs text-gray-500 mt-1">
                  Due: {formatDate(task.dueDate)}
                </p>
              </div>
              <span
                className={`px-2 py-1 text-xs rounded-full ${
                  task.priority === 'high'
                    ? 'bg-red-100 text-red-800'
                    : task.priority === 'medium'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-green-100 text-green-800'
                }`}
              >
                {task.priority}
              </span>
              <button
                onClick={() => handleDeleteTask(task.id)}
                disabled={state.isLoading}
                className="text-gray-400 hover:text-gray-600 disabled:opacity-50"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}