import React, { useState } from 'react';
import { X, Calendar, Trash2, BookOpen } from 'lucide-react';
import type { CalendarEvent } from '../../../types';
import { format, parseISO } from 'date-fns';
import { useCalendar } from '../../../contexts/CalendarContext';
import { useNavigate } from 'react-router-dom';
import { useLessons } from '../../../contexts/LessonContext';

interface EventPopoverProps {
  event: CalendarEvent;
  onClose: () => void;
  onDelete: (id: string) => void;
  onUpdate: (event: CalendarEvent) => void;
}

export default function EventPopover({ event, onClose, onDelete, onUpdate }: EventPopoverProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [startDate, setStartDate] = useState(format(parseISO(event.start), 'yyyy-MM-dd'));
  const { calculateEndDate } = useCalendar();
  const navigate = useNavigate();
  const { state: lessonState } = useLessons();

  const lessonCount = lessonState.lessons[event.unitId]?.length || 0;

  const handleDelete = () => {
    onDelete(event.id);
    onClose();
  };

  const handleUpdate = () => {
    const newStartDate = parseISO(startDate);
    const newEndDate = calculateEndDate(newStartDate, event.duration);

    onUpdate({
      ...event,
      start: newStartDate.toISOString(),
      end: newEndDate.toISOString(),
    });
    onClose();
  };

  const handlePlanLessons = () => {
    navigate(`/planning/lessons/${event.unitId}`, {
      state: { event }
    });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-gray-900">{event.title}</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-2 text-gray-600">
            <Calendar className="h-5 w-5" />
            {isEditing ? (
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="px-2 py-1 border rounded"
              />
            ) : (
              <span>{format(parseISO(event.start), 'MMM d, yyyy')}</span>
            )}
          </div>

          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Duration:</span>
            <span className="text-sm font-medium">{event.duration} days</span>
          </div>

          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Lessons planned:</span>
            <span className="text-sm font-medium">
              {lessonCount} of {event.duration}
            </span>
          </div>

          <div className="pt-4 flex justify-between border-t">
            <button
              onClick={handleDelete}
              className="flex items-center gap-2 px-3 py-2 text-sm text-red-600 hover:text-red-700"
            >
              <Trash2 className="h-4 w-4" />
              Delete
            </button>
            <div className="flex gap-2">
              {isEditing ? (
                <>
                  <button
                    onClick={() => setIsEditing(false)}
                    className="px-3 py-2 text-sm text-gray-600 hover:text-gray-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleUpdate}
                    className="px-3 py-2 text-sm text-indigo-600 hover:text-indigo-700"
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="px-3 py-2 text-sm text-gray-600 hover:text-gray-700"
                  >
                    Change Date
                  </button>
                  <button
                    onClick={handlePlanLessons}
                    className="flex items-center gap-2 px-3 py-2 text-sm text-indigo-600 hover:text-indigo-700"
                  >
                    <BookOpen className="h-4 w-4" />
                    Plan Lessons
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}