import React, { createContext, useContext, useReducer } from 'react';
import type { Lesson, LessonState } from '../types';

type LessonAction =
  | { type: 'ADD_LESSON'; payload: { unitId: string; lesson: Omit<Lesson, 'id'> } }
  | { type: 'UPDATE_LESSON'; payload: Lesson }
  | { type: 'DELETE_LESSON'; payload: { unitId: string; lessonId: string } }
  | { type: 'REORDER_LESSONS'; payload: { unitId: string; lessons: Lesson[] } };

interface LessonContextType {
  state: LessonState;
  addLesson: (unitId: string, lesson: Omit<Lesson, 'id'>) => void;
  updateLesson: (lesson: Lesson) => void;
  deleteLesson: (unitId: string, lessonId: string) => void;
  reorderLessons: (unitId: string, lessons: Lesson[]) => void;
}

const LessonContext = createContext<LessonContextType | undefined>(undefined);

const initialState: LessonState = {
  lessons: {},
};

function lessonReducer(state: LessonState, action: LessonAction): LessonState {
  switch (action.type) {
    case 'ADD_LESSON':
      const { unitId, lesson } = action.payload;
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [unitId]: [
            ...(state.lessons[unitId] || []),
            {
              ...lesson,
              id: Math.random().toString(36).substr(2, 9),
            },
          ],
        },
      };

    case 'UPDATE_LESSON':
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [action.payload.unitId]: state.lessons[action.payload.unitId].map(
            (lesson) => (lesson.id === action.payload.id ? action.payload : lesson)
          ),
        },
      };

    case 'DELETE_LESSON':
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [action.payload.unitId]: state.lessons[action.payload.unitId].filter(
            (lesson) => lesson.id !== action.payload.lessonId
          ),
        },
      };

    case 'REORDER_LESSONS':
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [action.payload.unitId]: action.payload.lessons,
        },
      };

    default:
      return state;
  }
}

export function LessonProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(lessonReducer, initialState);

  const addLesson = (unitId: string, lesson: Omit<Lesson, 'id'>) => {
    dispatch({ type: 'ADD_LESSON', payload: { unitId, lesson } });
  };

  const updateLesson = (lesson: Lesson) => {
    dispatch({ type: 'UPDATE_LESSON', payload: lesson });
  };

  const deleteLesson = (unitId: string, lessonId: string) => {
    dispatch({ type: 'DELETE_LESSON', payload: { unitId, lessonId } });
  };

  const reorderLessons = (unitId: string, lessons: Lesson[]) => {
    dispatch({ type: 'REORDER_LESSONS', payload: { unitId, lessons } });
  };

  return (
    <LessonContext.Provider
      value={{ state, addLesson, updateLesson, deleteLesson, reorderLessons }}
    >
      {children}
    </LessonContext.Provider>
  );
}

export function useLessons() {
  const context = useContext(LessonContext);
  if (context === undefined) {
    throw new Error('useLessons must be used within a LessonProvider');
  }
  return context;
}