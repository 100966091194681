import React, { useState } from 'react';
import { X, Check } from 'lucide-react';
import type { Unit, SubjectType } from '../../types';
import { useSavedUnits } from '../../contexts/SavedUnitsContext';
import SaveSuccessModal from './SaveSuccessModal';

interface UnitSelectionModalProps {
  units: Unit[];
  onClose: () => void;
}

export default function UnitSelectionModal({ units, onClose }: UnitSelectionModalProps) {
  const [selectedUnits, setSelectedUnits] = useState<Set<number>>(new Set());
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<SubjectType>('Math');
  const { addUnits } = useSavedUnits();

  const toggleUnit = (index: number) => {
    const newSelected = new Set(selectedUnits);
    if (selectedUnits.has(index)) {
      newSelected.delete(index);
    } else {
      newSelected.add(index);
    }
    setSelectedUnits(newSelected);
  };

  const toggleSelectAll = () => {
    if (selectedUnits.size === units.length) {
      // If all are selected, deselect all
      setSelectedUnits(new Set());
    } else {
      // Select all
      setSelectedUnits(new Set(units.map((_, index) => index)));
    }
  };

  const handleSave = () => {
    const unitsToSave = Array.from(selectedUnits).map(index => units[index]);
    addUnits([{ units: unitsToSave, subject: selectedSubject }]);
    setShowSuccessModal(true);
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    onClose();
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl max-w-3xl w-full max-h-[80vh] overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-900">Select Units to Save</h2>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="p-6">
            <div className="mb-4 space-y-4">
              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Subject
                </label>
                <select
                  id="subject"
                  value={selectedSubject}
                  onChange={(e) => setSelectedSubject(e.target.value as SubjectType)}
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="Math">Math</option>
                  <option value="Science">Science</option>
                  <option value="English">English</option>
                  <option value="Social Studies">Social Studies</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="flex items-center justify-between">
                <button
                  onClick={toggleSelectAll}
                  className="text-sm text-indigo-600 hover:text-indigo-800 flex items-center gap-2"
                >
                  <div className={`w-5 h-5 rounded border-2 flex items-center justify-center ${
                    selectedUnits.size === units.length
                      ? 'border-indigo-500 bg-indigo-500'
                      : 'border-gray-300'
                  }`}>
                    {selectedUnits.size === units.length && (
                      <Check className="h-3 w-3 text-white" />
                    )}
                  </div>
                  <span>Select All Units</span>
                </button>
                <span className="text-sm text-gray-500">
                  {selectedUnits.size} of {units.length} selected
                </span>
              </div>
            </div>

            <div className="space-y-4 overflow-y-auto max-h-[50vh]">
              {units.map((unit, index) => (
                <div
                  key={index}
                  className={`p-4 rounded-lg border-2 transition-colors cursor-pointer ${
                    selectedUnits.has(index)
                      ? 'border-indigo-500 bg-indigo-50'
                      : 'border-gray-200 hover:border-indigo-200'
                  }`}
                  onClick={() => toggleUnit(index)}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="font-medium text-gray-900">
                        Unit {index + 1}: {unit.unit_name}
                      </h3>
                      <p className="text-sm text-gray-500 mt-1">{unit.duration}</p>
                    </div>
                    <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                      selectedUnits.has(index)
                        ? 'border-indigo-500 bg-indigo-500'
                        : 'border-gray-300'
                    }`}>
                      {selectedUnits.has(index) && (
                        <Check className="h-4 w-4 text-white" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="p-6 border-t border-gray-200">
            <div className="flex justify-end gap-3">
              <button
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                disabled={selectedUnits.size === 0}
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Save Selected Units
              </button>
            </div>
          </div>
        </div>
      </div>

      {showSuccessModal && (
        <SaveSuccessModal
          unitCount={selectedUnits.size}
          onClose={handleSuccessClose}
        />
      )}
    </>
  );
}