import React, { useState } from 'react';
import { X } from 'lucide-react';
import { format, addDays } from 'date-fns';
import { useSavedUnits } from '../../contexts/SavedUnitsContext';
import LessonForm from '../../components/Planning/Lessons/LessonForm';
import OtherScheduleForm from './OtherScheduleForm';
import { subjectColors } from '../../utils/constants';

interface CreateLessonModalProps {
  weekStart: Date;
  onClose: () => void;
}

export default function CreateLessonModal({ weekStart, onClose }: CreateLessonModalProps) {
  const { state: unitsState } = useSavedUnits();
  const [selectedType, setSelectedType] = useState<'unit' | 'other'>('unit');
  const [selectedUnit, setSelectedUnit] = useState(unitsState.units[0]?.id || '');

  const unit = unitsState.units.find(u => u.id === selectedUnit);
  const weekDays = Array.from({ length: 5 }, (_, i) => addDays(weekStart, i));

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Add to Schedule</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            What would you like to add?
          </label>
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={() => setSelectedType('unit')}
              className={`p-4 rounded-lg border-2 text-left transition-colors ${
                selectedType === 'unit'
                  ? 'border-indigo-500 bg-indigo-50'
                  : 'border-gray-200 hover:border-indigo-200'
              }`}
            >
              <h3 className="font-medium text-gray-900">Unit Lesson</h3>
              <p className="text-sm text-gray-500 mt-1">
                Add a lesson from your curriculum units
              </p>
            </button>
            <button
              onClick={() => setSelectedType('other')}
              className={`p-4 rounded-lg border-2 text-left transition-colors ${
                selectedType === 'other'
                  ? 'border-indigo-500 bg-indigo-50'
                  : 'border-gray-200 hover:border-indigo-200'
              }`}
            >
              <h3 className="font-medium text-gray-900">Other Activity</h3>
              <p className="text-sm text-gray-500 mt-1">
                Add routines, events, or other activities
              </p>
            </button>
          </div>
        </div>

        {selectedType === 'unit' && (
          <>
            <div className="mb-6">
              <label htmlFor="unit" className="block text-sm font-medium text-gray-700 mb-1">
                Select Unit
              </label>
              <div className="relative">
                <select
                  id="unit"
                  value={selectedUnit}
                  onChange={(e) => setSelectedUnit(e.target.value)}
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pr-24"
                >
                  {unitsState.units.length === 0 ? (
                    <option value="">No units available</option>
                  ) : (
                    unitsState.units.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        Unit {unit.unitNumber}: {unit.unit_name}
                      </option>
                    ))
                  )}
                </select>
                {unit && (
                  <span 
                    className={`absolute right-2 top-1/2 -translate-y-1/2 px-2 py-1 rounded-full text-xs font-medium ${
                      subjectColors[unit.subject]?.bg || subjectColors.Other.bg
                    } ${subjectColors[unit.subject]?.text || subjectColors.Other.text}`}
                  >
                    {unit.subject}
                  </span>
                )}
              </div>
              {unit && (
                <p className="mt-2 text-sm text-gray-500">
                  This unit covers {unit.standards_covered.length} standards and {unit.learning_targets.length} learning targets
                </p>
              )}
            </div>

            {unit ? (
              <LessonForm
                unit={unit}
                onClose={onClose}
                schoolDays={weekDays}
                existingLessons={[]}
              />
            ) : (
              <div className="text-center py-6">
                <p className="text-gray-500">No units available. Please create a unit first.</p>
                <div className="mt-4">
                  <button
                    onClick={onClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        {selectedType === 'other' && (
          <OtherScheduleForm
            weekDays={weekDays}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
}