import React, { createContext, useContext, useReducer } from 'react';
import type { Unit, SavedUnit, SavedUnitsState, SubjectType } from '../types';

type SavedUnitsAction = 
  | { type: 'ADD_UNITS'; payload: { units: Unit[]; subject: SubjectType }[] }
  | { type: 'UPDATE_UNIT'; payload: SavedUnit }
  | { type: 'DELETE_UNIT'; payload: string }
  | { type: 'SET_SCHOOL_YEAR'; payload: string };

interface SavedUnitsContextType {
  state: SavedUnitsState;
  addUnits: (unitsWithSubject: { units: Unit[]; subject: SubjectType }[]) => void;
  updateUnit: (unit: SavedUnit) => void;
  deleteUnit: (id: string) => void;
  setSchoolYear: (year: string) => void;
}

const SavedUnitsContext = createContext<SavedUnitsContextType | undefined>(undefined);

const initialState: SavedUnitsState = {
  units: [],
  currentSchoolYear: '2023-2024'
};

function savedUnitsReducer(state: SavedUnitsState, action: SavedUnitsAction): SavedUnitsState {
  switch (action.type) {
    case 'ADD_UNITS':
      const currentMaxNumber = Math.max(0, ...state.units.map(u => u.unitNumber));
      const newUnits = action.payload.flatMap(({ units, subject }) => 
        units.map((unit, index) => ({
          ...unit,
          id: Math.random().toString(36).substr(2, 9),
          modified: false,
          lastModified: new Date().toISOString(),
          schoolYear: state.currentSchoolYear,
          originalUnit: unit,
          unitNumber: currentMaxNumber + index + 1,
          subject
        }))
      );
      return {
        ...state,
        units: [...state.units, ...newUnits]
      };
    case 'UPDATE_UNIT':
      return {
        ...state,
        units: state.units.map(unit => 
          unit.id === action.payload.id ? action.payload : unit
        )
      };
    case 'DELETE_UNIT':
      return {
        ...state,
        units: state.units.filter(unit => unit.id !== action.payload)
      };
    case 'SET_SCHOOL_YEAR':
      return {
        ...state,
        currentSchoolYear: action.payload
      };
    default:
      return state;
  }
}

export function SavedUnitsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(savedUnitsReducer, initialState);

  const addUnits = (unitsWithSubject: { units: Unit[]; subject: SubjectType }[]) => {
    dispatch({ type: 'ADD_UNITS', payload: unitsWithSubject });
  };

  const updateUnit = (unit: SavedUnit) => {
    dispatch({ type: 'UPDATE_UNIT', payload: unit });
  };

  const deleteUnit = (id: string) => {
    dispatch({ type: 'DELETE_UNIT', payload: id });
  };

  const setSchoolYear = (year: string) => {
    dispatch({ type: 'SET_SCHOOL_YEAR', payload: year });
  };

  return (
    <SavedUnitsContext.Provider value={{ state, addUnits, updateUnit, deleteUnit, setSchoolYear }}>
      {children}
    </SavedUnitsContext.Provider>
  );
}

export function useSavedUnits() {
  const context = useContext(SavedUnitsContext);
  if (context === undefined) {
    throw new Error('useSavedUnits must be used within a SavedUnitsProvider');
  }
  return context;
}