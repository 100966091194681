import React from 'react';
import { DndContext, DragEndEvent, DragOverlay, closestCenter, DragStartEvent } from '@dnd-kit/core';
import { addDays, format, isSameDay, startOfWeek, parseISO } from 'date-fns';
import DayColumn from './DayColumn';
import LessonCard from './LessonCard';
import { useCalendar } from '../../contexts/CalendarContext';
import { useLessons } from '../../contexts/LessonContext';
import type { Lesson } from '../../types';

interface WeekViewProps {
  currentWeek: Date;
  selectedDate: Date | null;
  onDaySelect: (date: Date) => void;
}

export default function WeekView({ currentWeek, selectedDate, onDaySelect }: WeekViewProps) {
  const { state: calendarState } = useCalendar();
  const { state: lessonState, updateLesson } = useLessons();
  const [activeLesson, setActiveLesson] = React.useState<Lesson | null>(null);

  // Get Monday of the week
  const monday = startOfWeek(currentWeek, { weekStartsOn: 1 });

  // Generate weekdays (Monday to Friday)
  const weekDays = Array.from({ length: 5 }, (_, i) => addDays(monday, i));

  // Get all lessons for the current week
  const weekLessons = Object.values(lessonState.lessons)
    .flat()
    .filter(lesson => {
      const lessonDate = parseISO(lesson.date);
      return weekDays.some(day => isSameDay(day, lessonDate));
    });

  const handleDragStart = (event: DragStartEvent) => {
    const lesson = weekLessons.find(l => l.id === event.active.id);
    if (lesson) {
      setActiveLesson(lesson);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveLesson(null);
    
    if (!over) return;

    const lessonId = active.id as string;
    const targetId = over.id as string;
    const lesson = weekLessons.find(l => l.id === lessonId);

    if (!lesson) return;

    // If dropping on another lesson, handle reordering
    const targetLesson = weekLessons.find(l => l.id === targetId);
    if (targetLesson) {
      const targetDate = format(parseISO(targetLesson.date), 'yyyy-MM-dd');
      const lessonsOnSameDay = weekLessons
        .filter(l => format(parseISO(l.date), 'yyyy-MM-dd') === targetDate)
        .sort((a, b) => a.order - b.order);

      // If moving within the same day
      if (format(parseISO(lesson.date), 'yyyy-MM-dd') === targetDate) {
        const oldIndex = lessonsOnSameDay.findIndex(l => l.id === lesson.id);
        const newIndex = lessonsOnSameDay.findIndex(l => l.id === targetLesson.id);

        // Remove the lesson from its current position
        const reorderedLessons = [...lessonsOnSameDay];
        reorderedLessons.splice(oldIndex, 1);

        // Insert it at the new position
        reorderedLessons.splice(newIndex, 0, lesson);

        // Update orders for all lessons
        reorderedLessons.forEach((l, index) => {
          if (l.order !== index) {
            updateLesson({
              ...l,
              order: index
            });
          }
        });
      } else {
        // Moving to a different day
        const sourceDate = format(parseISO(lesson.date), 'yyyy-MM-dd');
        const sourceDayLessons = weekLessons
          .filter(l => format(parseISO(l.date), 'yyyy-MM-dd') === sourceDate)
          .sort((a, b) => a.order - b.order);

        // Remove lesson from source day and reorder remaining lessons
        const remainingSourceLessons = sourceDayLessons
          .filter(l => l.id !== lesson.id)
          .map((l, index) => ({
            ...l,
            order: index
          }));

        // Insert lesson into target day
        const targetDayLessons = lessonsOnSameDay
          .filter(l => l.id !== lesson.id)
          .sort((a, b) => a.order - b.order);

        const insertIndex = targetDayLessons.findIndex(l => l.id === targetLesson.id);
        const updatedLesson = {
          ...lesson,
          date: targetDate,
          order: insertIndex
        };

        // Update orders for target day lessons after the insert point
        const targetUpdates = [
          ...targetDayLessons.slice(0, insertIndex),
          updatedLesson,
          ...targetDayLessons.slice(insertIndex).map(l => ({
            ...l,
            order: l.order + 1
          }))
        ];

        // Apply all updates
        [...remainingSourceLessons, ...targetUpdates].forEach(l => {
          updateLesson(l);
        });
      }
    } else {
      // Dropping on a day column
      const newDate = targetId;
      const sourceDayLessons = weekLessons
        .filter(l => format(parseISO(l.date), 'yyyy-MM-dd') === format(parseISO(lesson.date), 'yyyy-MM-dd'))
        .sort((a, b) => a.order - b.order);

      const targetDayLessons = weekLessons
        .filter(l => format(parseISO(l.date), 'yyyy-MM-dd') === newDate)
        .sort((a, b) => a.order - b.order);

      // Update orders for remaining lessons in source day
      const remainingSourceLessons = sourceDayLessons
        .filter(l => l.id !== lesson.id)
        .map((l, index) => ({
          ...l,
          order: index
        }));

      // Add lesson to target day
      const updatedLesson = {
        ...lesson,
        date: newDate,
        order: targetDayLessons.length
      };

      // Apply all updates
      [...remainingSourceLessons, updatedLesson].forEach(l => {
        updateLesson(l);
      });
    }
  };

  const getLessonsByDay = (date: Date): Lesson[] => {
    return weekLessons
      .filter(lesson => isSameDay(parseISO(lesson.date), date))
      .sort((a, b) => a.order - b.order);
  };

  return (
    <DndContext 
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="grid grid-cols-5 gap-4 bg-white rounded-xl shadow-sm p-4">
        {weekDays.map((day) => {
          const isNonSchoolDay = calendarState.nonSchoolDays.some(
            nonSchoolDay => isSameDay(parseISO(nonSchoolDay.date), day)
          );

          return (
            <DayColumn
              key={format(day, 'yyyy-MM-dd')}
              date={day}
              lessons={getLessonsByDay(day)}
              isNonSchoolDay={isNonSchoolDay}
              onDaySelect={onDaySelect}
              isSelected={selectedDate ? isSameDay(day, selectedDate) : false}
            />
          );
        })}
      </div>
      <DragOverlay>
        {activeLesson && (
          <div className="w-[250px]">
            <LessonCard 
              lesson={activeLesson} 
              onClick={() => {}} 
              isDragging 
            />
          </div>
        )}
      </DragOverlay>
    </DndContext>
  );
}