import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical, ChevronDown, ChevronUp, Pencil, Trash2 } from 'lucide-react';
import type { Lesson, SavedUnit } from '../../../types';
import { useLessons } from '../../../contexts/LessonContext';
import LessonForm from './LessonForm';
import { format } from 'date-fns';

interface LessonItemProps {
  lesson: Lesson;
  unit: SavedUnit;
  schoolDays: Date[];
}

export default function LessonItem({ lesson, unit, schoolDays }: LessonItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { deleteLesson } = useLessons();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: lesson.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDelete = () => {
    if (confirm('Are you sure you want to delete this lesson?')) {
      deleteLesson(unit.id, lesson.id);
    }
  };

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        className="bg-white border border-gray-200 rounded-lg shadow-sm"
      >
        <div className="p-4">
          <div className="flex items-center gap-4">
            <div
              {...attributes}
              {...listeners}
              className="cursor-move text-gray-400 hover:text-gray-600"
            >
              <GripVertical className="h-5 w-5" />
            </div>

            <div className="flex-1">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    {lesson.title}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {format(new Date(lesson.date), 'MMMM d, yyyy')}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setIsEditing(true)}
                    className="p-1 text-gray-400 hover:text-gray-600"
                  >
                    <Pencil className="h-4 w-4" />
                  </button>
                  <button
                    onClick={handleDelete}
                    className="p-1 text-red-400 hover:text-red-600"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="p-1 text-gray-400 hover:text-gray-600"
                  >
                    {isExpanded ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {isExpanded && (
            <div className="mt-4 pl-9 space-y-4">
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-2">
                  Standards Covered
                </h4>
                <ul className="list-disc list-inside space-y-1">
                  {lesson.standards.map((standardId) => {
                    const standard = unit.standards_covered.find(
                      (s) => s.standard === standardId
                    );
                    return (
                      <li key={standardId} className="text-sm text-gray-600">
                        {standard?.standard}: {standard?.description}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-2">
                  Learning Targets
                </h4>
                <ul className="list-disc list-inside space-y-1">
                  {lesson.learningTargets.map((targetId, index) => {
                    const target = unit.learning_targets[parseInt(targetId)];
                    return (
                      <li key={index} className="text-sm text-gray-600">
                        {target}
                      </li>
                    );
                  })}
                </ul>
              </div>

              {lesson.description && (
                <div>
                  <h4 className="text-sm font-medium text-gray-900 mb-2">
                    Description
                  </h4>
                  <p className="text-sm text-gray-600">{lesson.description}</p>
                </div>
              )}

              {lesson.resources && (
                <div>
                  <h4 className="text-sm font-medium text-gray-900 mb-2">
                    Resources
                  </h4>
                  <p className="text-sm text-gray-600">{lesson.resources}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {isEditing && (
        <LessonForm
          unit={unit}
          onClose={() => setIsEditing(false)}
          schoolDays={schoolDays}
          existingLessons={[]}
          editingLesson={lesson}
        />
      )}
    </>
  );
}