import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Notifications from './pages/Notifications';
import Planning from './pages/Planning';
import Schedule from './pages/Schedule';
import FullYearPlan from './pages/Planning/FullYearPlan';
import SavedUnits from './pages/Planning/SavedUnits';
import Calendar from './pages/Planning/Calendar';
import LessonPlanner from './pages/Planning/LessonPlanner';
import { AlertProvider } from './contexts/AlertContext';
import { TaskProvider } from './contexts/TaskContext';
import { PlanRequestProvider } from './contexts/PlanRequestContext';
import { SavedUnitsProvider } from './contexts/SavedUnitsContext';
import { CalendarProvider } from './contexts/CalendarContext';
import { LessonProvider } from './contexts/LessonContext';
import { SubjectsProvider } from './contexts/SubjectsContext';
import { AuthProvider } from './contexts/AuthContext';
import { SecurityProvider } from './contexts/SecurityContext';
import SecurityGate from './components/Security/SecurityGate';
import LoginForm from './components/Auth/LoginForm';
import RegisterForm from './components/Auth/RegisterForm';
import VerifyEmailForm from './components/Auth/VerifyEmailForm';
import PrivateRoute from './components/Auth/PrivateRoute';

function App() {
  return (
    <Router>
      <SecurityProvider>
        <SecurityGate>
          <AuthProvider>
            <AlertProvider>
              <TaskProvider>
                <PlanRequestProvider>
                  <SubjectsProvider>
                    <SavedUnitsProvider>
                      <CalendarProvider>
                        <LessonProvider>
                          <Routes>
                            <Route path="/login" element={<LoginForm />} />
                            <Route path="/register" element={<RegisterForm />} />
                            <Route path="/verify-email" element={<VerifyEmailForm />} />
                            <Route path="/*" element={
                              <PrivateRoute>
                                <MainLayout />
                              </PrivateRoute>
                            } />
                          </Routes>
                        </LessonProvider>
                      </CalendarProvider>
                    </SavedUnitsProvider>
                  </SubjectsProvider>
                </PlanRequestProvider>
              </TaskProvider>
            </AlertProvider>
          </AuthProvider>
        </SecurityGate>
      </SecurityProvider>
    </Router>
  );
}

function MainLayout() {
  return (
    <div className="flex min-h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1">
        <Header />
        <main className="p-6">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/planning" element={<Planning />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/planning/full-year" element={<FullYearPlan />} />
            <Route path="/planning/saved-units" element={<SavedUnits />} />
            <Route path="/planning/calendar" element={<Calendar />} />
            <Route path="/planning/lessons/:unitId" element={<LessonPlanner />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default App;