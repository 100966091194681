import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import type { SavedUnit } from '../../../types';
import { useCalendar } from '../../../contexts/CalendarContext';

interface UnitSidebarProps {
  units: SavedUnit[];
}

function DraggableUnit({ unit }: { unit: SavedUnit }) {
  const { state: { events } } = useCalendar();
  const isPlaced = events.some(event => event.unitId === unit.id);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `unit-${unit.id}`,
    data: unit,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className={`p-4 bg-white rounded-lg shadow-sm border border-gray-200 cursor-move hover:shadow-md transition-shadow ${
        isPlaced ? 'opacity-50' : ''
      }`}
    >
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm font-medium text-gray-900">
          Unit {unit.unitNumber}
        </span>
        <span className="px-2 py-0.5 text-xs bg-indigo-100 text-indigo-800 rounded-full">
          {unit.duration}
        </span>
      </div>
      <h4 className="text-sm text-gray-700 line-clamp-2">{unit.unit_name}</h4>
      {isPlaced && (
        <p className="text-xs text-gray-500 mt-1">
          Already scheduled - drag again to reschedule
        </p>
      )}
    </div>
  );
}

export default function UnitSidebar({ units }: UnitSidebarProps) {
  return (
    <div className="w-80 bg-white rounded-xl shadow-sm p-4">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">Available Units</h3>
      <div className="space-y-3">
        {units.map(unit => (
          <DraggableUnit key={unit.id} unit={unit} />
        ))}
      </div>
    </div>
  );
}