import { Task } from '../types';

const API_ENDPOINT = 'https://89szhn5ttl.execute-api.us-east-1.amazonaws.com/production';

export class APIError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'APIError';
  }
}

export async function getTasks(userId: string): Promise<Task[]> {
  try {
    const response = await fetch(`${API_ENDPOINT}/tasks?userId=${userId}`, {
      headers: {
        'Accept': 'application/json',
      },
    });
    
    if (!response.ok) {
      throw new APIError(`HTTP error! status: ${response.status}`, response.status);
    }
    
    const data = await response.json();
    return Array.isArray(data?.Items) ? data.Items : [];
  } catch (error) {
    if (error instanceof APIError) throw error;
    throw new APIError('Failed to fetch tasks');
  }
}

export async function createTask(userId: string, task: Omit<Task, 'id'>): Promise<Task> {
  try {
    const response = await fetch(`${API_ENDPOINT}/tasks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        userId,
        ...task,
      }),
    });

    if (!response.ok) {
      throw new APIError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    if (!data?.Item) {
      throw new APIError('Invalid response format from server');
    }

    return data.Item;
  } catch (error) {
    if (error instanceof APIError) throw error;
    throw new APIError('Failed to create task');
  }
}

export async function updateTask(userId: string, task: Task): Promise<Task> {
  try {
    const response = await fetch(`${API_ENDPOINT}/tasks`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        userId,
        ...task,
      }),
    });

    if (!response.ok) {
      throw new APIError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    if (!data?.Item) {
      throw new APIError('Invalid response format from server');
    }

    return data.Item;
  } catch (error) {
    if (error instanceof APIError) throw error;
    throw new APIError('Failed to update task');
  }
}

export async function deleteTask(userId: string, taskId: string): Promise<void> {
  try {
    const response = await fetch(`${API_ENDPOINT}/tasks?userId=${userId}&taskId=${taskId}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new APIError(`HTTP error! status: ${response.status}`, response.status);
    }
  } catch (error) {
    if (error instanceof APIError) throw error;
    throw new APIError('Failed to delete task');
  }
}