import React, { useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { format } from 'date-fns';
import LessonCard from './LessonCard';
import type { Lesson } from '../../types';
import LessonDetailsModal from './LessonDetailsModal';

interface DayColumnProps {
  date: Date;
  lessons: Lesson[];
  isNonSchoolDay: boolean;
  onDaySelect: (date: Date) => void;
  isSelected: boolean;
}

export default function DayColumn({ date, lessons, isNonSchoolDay, onDaySelect, isSelected }: DayColumnProps) {
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>(null);
  const dateStr = format(date, 'yyyy-MM-dd');
  
  const { setNodeRef, isOver } = useDroppable({
    id: dateStr,
  });

  return (
    <>
      <div
        ref={setNodeRef}
        className={`min-h-[600px] p-4 rounded-lg transition-colors ${
          isNonSchoolDay ? 'bg-gray-50' : 'bg-gray-50/30'
        } ${isOver ? 'ring-2 ring-indigo-400 ring-inset bg-indigo-50/50' : ''} 
        ${isSelected ? 'ring-2 ring-indigo-500' : ''}`}
      >
        <button
          onClick={() => onDaySelect(date)}
          className="w-full text-center pb-4 border-b border-gray-200 hover:bg-gray-50 rounded-t-lg transition-colors"
        >
          <p className="font-medium text-gray-900">
            {format(date, 'EEEE')}
          </p>
          <p className="text-sm text-gray-500">
            {format(date, 'MMM d')}
          </p>
        </button>

        {isNonSchoolDay ? (
          <div className="flex items-center justify-center h-32">
            <p className="text-sm text-gray-500">No School</p>
          </div>
        ) : (
          <div className="space-y-2 mt-4">
            {lessons.map((lesson) => (
              <LessonCard
                key={lesson.id}
                lesson={lesson}
                onClick={() => setSelectedLesson(lesson)}
              />
            ))}
          </div>
        )}
      </div>

      {selectedLesson && (
        <LessonDetailsModal
          lesson={selectedLesson}
          onClose={() => setSelectedLesson(null)}
        />
      )}
    </>
  );
}