import React from 'react';
import { format, parseISO } from 'date-fns';
import { Clock, CalendarDays } from 'lucide-react';
import type { Lesson } from '../../types';
import { useSavedUnits } from '../../contexts/SavedUnitsContext';
import { subjectColors } from '../../utils/constants';

interface DayDetailsProps {
  date: Date;
  lessons: Lesson[];
  onEditLesson: (lesson: Lesson) => void;
}

export default function DayDetails({ date, lessons, onEditLesson }: DayDetailsProps) {
  const { state: unitsState } = useSavedUnits();

  const sortedLessons = [...lessons].sort((a, b) => {
    // First sort by order
    if (a.order !== b.order) return a.order - b.order;
    
    // Then by time if available (for other activities)
    const aTime = a.description?.match(/Time: (.*?)\n/)?.[1] || '';
    const bTime = b.description?.match(/Time: (.*?)\n/)?.[1] || '';
    return aTime.localeCompare(bTime);
  });

  return (
    <div className="mt-8 bg-white rounded-xl shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <CalendarDays className="h-6 w-6 text-indigo-600" />
          <h2 className="text-xl font-semibold text-gray-900">
            {format(date, 'EEEE, MMMM d, yyyy')}
          </h2>
        </div>
        <span className="text-sm text-gray-500">
          {sortedLessons.length} {sortedLessons.length === 1 ? 'activity' : 'activities'} scheduled
        </span>
      </div>

      <div className="space-y-4">
        {sortedLessons.map((lesson) => {
          const unit = lesson.unitId !== 'other' 
            ? unitsState.units.find(u => u.id === lesson.unitId)
            : null;
          
          const isOtherActivity = lesson.unitId === 'other';
          const colors = unit ? subjectColors[unit.subject] || subjectColors.Other : subjectColors.Other;
          
          // For other activities
          const activityType = isOtherActivity ? lesson.description?.match(/^Type: (.*?)\n/)?.[1] || 'Other' : null;
          const activityTime = isOtherActivity ? lesson.description?.match(/Time: (.*?)\n/)?.[1] : null;
          const description = isOtherActivity 
            ? lesson.description?.replace(/^Type: .*\n/, '').replace(/^Time: .*\n/, '')
            : lesson.description;

          return (
            <div 
              key={lesson.id}
              className="flex gap-6 p-4 rounded-lg hover:bg-gray-50 transition-colors group"
            >
              {activityTime && (
                <div className="flex items-center gap-2 text-gray-500">
                  <Clock className="h-4 w-4" />
                  <span className="text-sm whitespace-nowrap">{activityTime}</span>
                </div>
              )}
              
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2 mb-1">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${colors.bg} ${colors.text}`}>
                    {isOtherActivity ? activityType : unit?.subject}
                  </span>
                  {!isOtherActivity && unit && (
                    <span className="text-sm text-gray-500">
                      Unit {unit.unitNumber}
                    </span>
                  )}
                </div>
                
                <h3 className="text-lg font-medium text-gray-900">{lesson.title}</h3>
                {!isOtherActivity && unit && (
                  <p className="text-sm text-gray-600 mb-2">{unit.unit_name}</p>
                )}
                
                {description && (
                  <p className="text-sm text-gray-600">{description}</p>
                )}

                {!isOtherActivity && (
                  <div className="mt-4 space-y-3">
                    {lesson.standards.length > 0 && (
                      <div>
                        <h4 className="text-sm font-medium text-gray-700">Standards:</h4>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {lesson.standards.map(standardId => {
                            const standard = unit?.standards_covered.find(s => s.standard === standardId);
                            return standard && (
                              <span 
                                key={standardId}
                                className="px-2 py-1 bg-gray-100 rounded text-xs text-gray-600"
                              >
                                {standard.standard}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {lesson.learningTargets.length > 0 && (
                      <div>
                        <h4 className="text-sm font-medium text-gray-700">Learning Targets:</h4>
                        <ul className="mt-1 space-y-1">
                          {lesson.learningTargets.map((targetId) => {
                            const target = unit?.learning_targets[parseInt(targetId)];
                            return target && (
                              <li 
                                key={targetId}
                                className="text-sm text-gray-600 flex items-start gap-2"
                              >
                                <span className="text-gray-400">•</span>
                                <span>{target}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <button
                onClick={() => onEditLesson(lesson)}
                className="opacity-0 group-hover:opacity-100 px-3 py-1 text-sm text-indigo-600 hover:text-indigo-800 hover:bg-indigo-50 rounded transition-all"
              >
                Edit
              </button>
            </div>
          );
        })}

        {sortedLessons.length === 0 && (
          <div className="text-center py-12 text-gray-500">
            No activities scheduled for this day
          </div>
        )}
      </div>
    </div>
  );
}