import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, Archive, CalendarDays, BookOpen, X } from 'lucide-react';
import { useSavedUnits } from '../../contexts/SavedUnitsContext';
import { useCalendar } from '../../contexts/CalendarContext';
import { useLessons } from '../../contexts/LessonContext';
import { format, parseISO } from 'date-fns';

const planningTools = [
  {
    title: 'Year-Long Plan',
    description: 'Generate a comprehensive year-long curriculum plan based on standards',
    icon: Calendar,
    path: '/planning/full-year',
  },
  {
    title: 'Unit Plans',
    description: 'Create and manage your curriculum units',
    icon: Archive,
    path: '/planning/saved-units',
  },
  {
    title: 'Calendar',
    description: 'Schedule and organize your units across the school year',
    icon: CalendarDays,
    path: '/planning/calendar',
  },
];

export default function Planning() {
  const { state: unitsState } = useSavedUnits();
  const { state: calendarState } = useCalendar();
  const { state: lessonState } = useLessons();
  const [showLessonModal, setShowLessonModal] = useState(false);

  // Calculate total scheduled units and planned lessons
  const scheduledUnits = calendarState.events;
  const totalLessons = Object.values(lessonState.lessons).reduce(
    (sum, unitLessons) => sum + unitLessons.length,
    0
  );

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Planning Tools</h1>
        <p className="text-gray-500">Select a planning tool to get started</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {planningTools.map(({ title, description, icon: Icon, path }) => (
          <Link
            key={title}
            to={path}
            className="block p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="flex items-start gap-4">
              <div className="p-3 bg-indigo-50 rounded-lg">
                <Icon className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
                <p className="mt-1 text-gray-500">{description}</p>
                {title === 'Unit Plans' && unitsState.units.length > 0 && (
                  <p className="mt-2 text-sm text-indigo-600">
                    {unitsState.units.length} units saved
                  </p>
                )}
                {title === 'Calendar' && scheduledUnits.length > 0 && (
                  <p className="mt-2 text-sm text-indigo-600">
                    {scheduledUnits.length} units scheduled
                  </p>
                )}
              </div>
            </div>
          </Link>
        ))}

        {scheduledUnits.length > 0 && (
          <button
            onClick={() => setShowLessonModal(true)}
            className="block p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow text-left w-full"
          >
            <div className="flex items-start gap-4">
              <div className="p-3 bg-indigo-50 rounded-lg">
                <BookOpen className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <h3 className="text-lg font-semibold text-gray-900">Lesson Planning</h3>
                  <span className="px-2 py-0.5 text-xs bg-indigo-100 text-indigo-800 rounded-full">
                    {totalLessons} lessons planned
                  </span>
                </div>
                <p className="mt-1 text-gray-500">Create and manage daily lessons for your scheduled units</p>
                <p className="mt-2 text-sm text-indigo-600">
                  Click to view scheduled units
                </p>
              </div>
            </div>
          </button>
        )}
      </div>

      {showLessonModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl max-w-2xl w-full p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-semibold text-gray-900">Scheduled Units</h2>
              <button
                onClick={() => setShowLessonModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="space-y-4">
              {scheduledUnits.map((event) => {
                const lessonCount = lessonState.lessons[event.unitId]?.length || 0;
                return (
                  <Link
                    key={event.id}
                    to={`/planning/lessons/${event.unitId}`}
                    state={{ event }}
                    className="block p-4 bg-white border border-gray-200 rounded-lg hover:border-indigo-200 transition-colors"
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium text-gray-900">{event.title}</h3>
                        <p className="text-sm text-gray-500">
                          {format(parseISO(event.start), 'MMM d')} - {format(parseISO(event.end), 'MMM d, yyyy')}
                        </p>
                      </div>
                      <div className="text-right">
                        <span className="text-sm text-gray-600">
                          {lessonCount} of {event.duration} lessons planned
                        </span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}