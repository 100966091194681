import React from 'react';
import {
  LayoutDashboard,
  Users,
  Calendar,
  BookOpen,
  MessageSquare,
  ClipboardList,
  Settings,
  GraduationCap,
  Bell,
} from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useAlerts } from '../contexts/AlertContext';

const navItems = [
  { icon: LayoutDashboard, label: 'Dashboard', path: '/' },
  { icon: Users, label: 'Students', path: '/students' },
  { icon: BookOpen, label: 'Planning', path: '/planning' },
  { icon: Calendar, label: 'Schedule', path: '/schedule' },
  { icon: GraduationCap, label: 'Assessments', path: '/assessments' },
  { icon: MessageSquare, label: 'Communication', path: '/communication' },
  { icon: Bell, label: 'Notifications', path: '/notifications' },
  { icon: ClipboardList, label: 'Resources', path: '/resources' },
  { icon: Settings, label: 'Settings', path: '/settings' },
];

export default function Sidebar() {
  const location = useLocation();
  const { state } = useAlerts();
  const unreadCount = state.alerts.length;

  return (
    <aside className="bg-indigo-900 text-white w-64 min-h-screen p-4">
      <div className="flex items-center gap-2 mb-8 px-2">
        <GraduationCap className="w-8 h-8" />
        <span className="text-xl font-bold">NinjaED</span>
      </div>
      
      <nav className="space-y-2">
        {navItems.map(({ icon: Icon, label, path }) => (
          <Link
            key={path}
            to={path}
            className={`flex items-center gap-3 px-4 py-3 rounded-lg transition-colors
              ${location.pathname === path 
                ? 'bg-indigo-800 text-white' 
                : 'text-indigo-100 hover:bg-indigo-800/50'}`}
          >
            <div className="relative">
              <Icon className="w-5 h-5" />
              {label === 'Notifications' && unreadCount > 0 && (
                <span className="absolute -top-1 -right-1 h-4 w-4 bg-red-500 rounded-full text-xs flex items-center justify-center">
                  {unreadCount}
                </span>
              )}
            </div>
            <span>{label}</span>
          </Link>
        ))}
      </nav>
    </aside>
  );
}