import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useCalendar } from '../../../contexts/CalendarContext';
import { format, parseISO, startOfDay } from 'date-fns';

interface NonSchoolDayModalProps {
  onClose: () => void;
}

export default function NonSchoolDayModal({ onClose }: NonSchoolDayModalProps) {
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [description, setDescription] = useState('');
  const [type, setType] = useState<'holiday' | 'break' | 'other'>('holiday');
  const { addNonSchoolDay } = useCalendar();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (date && description) {
      // Parse the date string and ensure it's handled consistently
      const selectedDate = parseISO(date);
      const normalizedDate = startOfDay(selectedDate).toISOString();
      
      addNonSchoolDay({
        date: normalizedDate,
        description,
        type,
      });
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Add Non-School Day</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
              Date
            </label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </div>

          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
              Type
            </label>
            <select
              id="type"
              value={type}
              onChange={(e) => setType(e.target.value as 'holiday' | 'break' | 'other')}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="holiday">Holiday</option>
              <option value="break">Break</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="e.g., Spring Break, Teacher In-Service Day"
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="pt-4 flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            >
              Add Non-School Day
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}